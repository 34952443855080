import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Toaster, toast } from 'react-hot-toast';
import '../styles/home.css';
import '../styles/spinner.css';
import chica from '../assets/chicaheader.png';

const Home = () => {
    const [invoiceCode, setInvoiceCode] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        birthdate: '',
        address: '',
        email: '',
        phone: '',
    });
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [isGarantiaValidada, setIsGarantiaValidada] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const email = urlParams.get('email');

        if (code && email) {
            verificarGarantia(code, email);
        }
    }, []);

    const decodeBase64 = (str) => {
        return decodeURIComponent(atob(str));
    };

    const verificarGarantia = async (code, email) => {
        try {
            let decodedCode = decodeBase64(code);
            const decodedEmail = decodeBase64(email);

            // omitir en decodedCode las palabras FACEL- y -NVC01
            
            decodedCode = decodedCode.replace('FACEL-', '');
            decodedCode = decodedCode.replace('-NVC01', '');

            
            const response = await fetch(`https://garantias-novi.ngrok.app/validar-garantia?code=${encodeURIComponent(decodedCode)}&email=${encodeURIComponent(decodedEmail)}`);
            const data = await response.json();

            if (data.garantiaValida) {
                setIsGarantiaValidada(true);
                toast.success('Garantía validada correctamente');
            } else {
                toast.error('Error al validar la garantía');
            }
        } catch (error) {
            toast.error('Error al validar la garantía');
            console.error('Error al validar la garantía:', error);
        }
    };

    const handleInvoiceCodeChange = (e) => {
        setInvoiceCode(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleNombreChange = (e) => {
        const name = e.target.value;
        if (/^[a-zA-Z\s]*$/.test(name)) {
            setFormData({
                ...formData,
                name
            });
        }
    };

    const handleCorreoChange = (e) => {
        const email = e.target.value;
        if (!/\s/.test(email)) {
            setFormData({
                ...formData,
                email
            });
        }
    };

    const handleKeyPressCorreo = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleTelefonoChange = (e) => {
        const phone = e.target.value;
        if (/^[0-9]*$/.test(phone)) {
            setFormData({
                ...formData,
                phone
            });
        }
    };

    const handleTermsChange = (event) => {
        setIsTermsAccepted(event.target.checked);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('https://garantias-novi.ngrok.app/registrar-garantia', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    codigoFactura: invoiceCode,
                    nombre: formData.name,
                    fechaNacimiento: formData.birthdate,
                    correo: formData.email,
                    telefono: formData.phone,
                    direccion: formData.address,
                    isTermsAccepted: isTermsAccepted
                })
            });
            if (response.ok) {
                const data = await response.json();
                toast.success('Formulario enviado exitosamente');
                console.log('Formulario enviado:', data);
            } else {
                toast.error('Error al enviar el formulario');
                console.error('Error al enviar el formulario:', response.statusText);
            }
        } catch (error) {
            toast.error('Error al enviar el formulario');
            console.error('Error al enviar el formulario:', error);
        }
    };

    const isFormValid = formData.name && formData.address && formData.email && isTermsAccepted;
    const currentDate = new Date().toISOString().split('T')[0];

    return (
        <>
            <div className='home'>
                <div className='header'>
                    <h1 className='header-title'>Validación de Garantía</h1>
                    <img src={chica} alt='Chica sonriendo con hoja de garantía' width='200' height='200' className='header-image' />
                </div>

                <Container className='content' xs={12} md={8} lg={6}>
                    <Toaster position="top-right" reverseOrder={false} />
                    {isGarantiaValidada ? (
                        <div className='validacion-exitosa'>
                            <h2>Tu garantía ya ha sido validada.</h2>
                        </div>
                    ) : (
                        <div className='invoice-section'>
                            <Form onSubmit={handleSubmit} className="user-form mt-4 invoice-form">
                                <Form.Group controlId="formInvoiceCode">
                                    <Form.Label className="mb-3">Código de Factura</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control
                                            type="text"
                                            value={invoiceCode}
                                            onChange={handleInvoiceCodeChange}
                                            placeholder="Ingrese el código de factura"
                                            className="input-invoice-code"
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formName">
                                    <Form.Label>Nombre <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleNombreChange}
                                        placeholder="Ingrese su nombre"
                                        className="input-invoice-code"
                                        maxLength="50"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBirthdate">
                                    <Form.Label>Fecha de Nacimiento <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="birthdate"
                                        value={formData.birthdate}
                                        onChange={handleInputChange}
                                        min="1900-01-01"
                                        max={currentDate}
                                        placeholder="Ingrese su fecha de nacimiento"
                                        className="input-invoice-code"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formAddress">
                                    <Form.Label>Dirección <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        placeholder="Ingrese su dirección"
                                        className="input-invoice-code"
                                        maxLength="100"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Correo Electrónico <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onKeyPress={handleKeyPressCorreo}
                                        onChange={handleCorreoChange}
                                        placeholder="Ingrese su correo electrónico"
                                        className="input-invoice-code"
                                        maxLength="50"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPhone">
                                    <Form.Label>Teléfono <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleTelefonoChange}
                                        placeholder="Ingrese su teléfono"
                                        className="input-invoice-code"
                                        maxLength="20"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formTerms">
                                    <Form.Check
                                        type="checkbox"
                                        label={<span>Acepto los <a href="https://www.iubenda.com/privacy-policy/36820790/legal" target="_blank" rel="noopener noreferrer">términos y condiciones</a></span>}
                                        className="checkbox-terms"
                                        checked={isTermsAccepted}
                                        onChange={handleTermsChange}
                                    />
                                </Form.Group>
                                <br />
                                <Button variant="primary" type="submit" className="btn-invoice-code" disabled={!isFormValid}>
                                    Enviar
                                </Button>
                            </Form>
                        </div>
                    )}
                </Container>
            </div>
        </>
    );
};

export default Home;
